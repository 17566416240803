import React from "react";
import styled from "styled-components";
import { Section, Container } from "@components/global";

const NewsItems = [
  {
    title: "Coronavirus",
    content: () => (
      <p>
        Gerne unterstützen wir Sie auch weiter auf Ihrem Gesundungsweg. Hierfür
        haben wir erhöhte Hygienemaßnahmen eingeführt. Ihre Gesundheit liegt uns am
        Herzen!
      </p>
    ),
  },
  // {
  //   title: "Wir suchen Verstärkung!",
  //   content: () => (
  //     <>
  //       <p>
  //         Du bist auf der Suche nach einer kollegialen Praxis, die vielfätige
  //         Aufgaben zu bieten hat und in der Du selbstständig arbeiten kannst? Du
  //         möchtest Verantwortung über Deinen eigenen Teilbereich übernehmen und
  //         stets die Möglichkeit haben, Dich mit Deinen Kollegen fachlich
  //         auszutauschen? Dann bist Du hier genau richtig!
  //       </p>
  //       <br />
  //       <br />
  //       Wir bieten:
  //       <ul>
  //         <li>
  //           {" "}
  //           <ListP> ein nettes, kollegiales Umfeld</ListP>{" "}
  //         </li>
  //         <li>
  //           {" "}
  //           <ListP> ein überdurchschnittliches Gehalt</ListP>{" "}
  //         </li>
  //         <li>
  //           {" "}
  //           <ListP> neue, modern ausgestattete Praxisräume</ListP>{" "}
  //         </li>
  //         <li>
  //           {" "}
  //           <ListP> ungestörtes Behandeln in geschlossenen Räumen</ListP>{" "}
  //         </li>
  //         <li>
  //           {" "}
  //           <ListP>
  //             großzügige Unterstützung für physiotherapeutische Fortbildungen
  //           </ListP>{" "}
  //         </li>
  //         <li>
  //           {" "}
  //           <ListP> weitestgehend flexible Arbeitszeiten</ListP>{" "}
  //         </li>
  //       </ul>
  //       <p>
  //         Haben wir Dein Interesse geweckt? Melde Dich gerne telefonisch oder
  //         per E-Mail.
  //       </p>
  //     </>
  //   ),
  // },
];

const News = () => (
  <Section id="aktuelles">
    <Container>
      <h2 style={{ marginBottom: 40 }}>Aktuelles</h2>
      <div>
        {NewsItems.map(({ title, content }) => (
          <div style={{ margin: "3rem" }} key={title}>
            <h3>{title}</h3>
            <>{content()}</>
          </div>
        ))}
      </div>
    </Container>
  </Section>
);

const StyledListItem = styled.li`
  color: ${(props) => props.theme.color.black.light}
  margin: 1rem;
`;

const ListP = styled.p`
  margin: 0.5rem;
`;

export default News;
