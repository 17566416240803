import React from "react";
import styled from "styled-components";
import { StaticQuery, graphql } from "gatsby";

import { Section, Container } from "@components/global";

const About = () => (
  <AboutSection id="über mich">
    <Container>
      <Grid>
        <div>
          <h2>Willkommen</h2>
          <p>
            Mein Team und ich heißen Sie herzlich in unserer Praxis willkommen.
            Mit Leidenschaft, Wissen und Erfahrung helfen wir Ihnen gerne weiter. Dabei
            steht Ihr Wohlbefinden für uns immer im Vordergrund.
          </p>
        </div>
        <Art>{/* <Img fluid={data.art_fast.childImageSharp.fluid} /> */}</Art>
      </Grid>
      <Grid inverse>
        <Art>{/* <Img fluid={data.art_learn.childImageSharp.fluid} /> */}</Art>
        <div>
          <h2>Über Mich</h2>
          <p>
            Seit mehr als 30 Jahren bin ich Physiotherapeutin mit Leib und
            Seele. Das Zusammenspiel aus Gesundheit und Bewegung hat mich schon
            immer fasziniert. Deshalb habe ich mich nach der Weiterbildung zur
            Osteopathin selbstständig gemacht und meine Praxis in Sankt Georgen
            im Schwarzwald eröffnet.
          </p>
        </div>
      </Grid>
      <Grid>
        <div>
          <h2>Meine Überzeugung</h2>
          <p>
            Gezielt passe ich meine Behandlung an Ihre Bedürfnisse an. Für einen
            nachhaltigen Therapieerfolg bringe ich Ihnen außerdem
            Mobilisations-, Kräftigungs- und Dehnungsübungen bei.
            <br />
            <br />
            Zusammen bringen wir Ihren Körper wieder in seine Mitte. Das Ziel
            ist es, Schmerzen zu lindern, die Beweglichkeit zu fördern und so
            insgesamt Ihr körperliches und seelisches Wohlbefinden wieder
            herzustellen. Sie entspannen in ruhiger Atmosphäre und lassen die
            Behandlung auf sich wirken. Das sind beste Voraussetzungen für Ihre
            Genesung.
          </p>
        </div>
        <Art>{/* <Img fluid={data.art_ideas.childImageSharp.fluid} /> */}</Art>
      </Grid>
    </Container>
  </AboutSection>
);

const Grid = styled.div`
  display: grid;
  grid-template-columns: 3fr 1fr;
  grid-gap: 5rem;
  text-align: left;
  align-items: center;
  justify-items: center;
  margin: 3rem 0;

  &:first-child {
    margin: 0 !important;
  }

  ${(props) =>
    props.inverse &&
    `
    text-align: right;
    grid-template-columns: 1fr 3fr;
  `}

  h2 {
    margin-bottom: 16px;
  }

  @media (max-width: ${(props) => props.theme.screen.md}) {
    grid-template-columns: 1fr;
    text-align: left;
    margin-bottom: 96px;

    &:last-child {
      margin-bottom: 24px;
    }

    ${(props) =>
      props.inverse &&
      `
        ${Art} {
          order: 2;
        }
    `}
  }
`;

const AboutSection = styled(Section)`
  padding: 150px 0;
`;

const Art = styled.figure`
  margin: 0;
  max-width: 380px;
  width: 100%;
`;

export default About;
