import React from "react";
import styled from "styled-components";
import { StaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";
import { Link } from "gatsby";

import { Container } from "@components/global";

const Footer = () => (
  <StaticQuery
    query={graphql`
      query {
        logo_dto: file(
          sourceInstanceName: { eq: "logos" }
          name: { eq: "verband_dt_osteopathen" }
        ) {
          childImageSharp {
            fluid(maxWidth: 400) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={(data) => (
      <React.Fragment>
        <FooterWrapper>
          <StyledContainer>
            <ImprintLink to="/impressum">Impressum</ImprintLink>
            <Art>
              <Img fluid={data.logo_dto.childImageSharp.fluid} height="100px" />
            </Art>
          </StyledContainer>
        </FooterWrapper>
      </React.Fragment>
    )}
  />
);

const FooterWrapper = styled.footer`
  background-color: ${(props) => props.theme.color.primary};
  // padding: 32px 0;
`;

const Copyright = styled.div`
  font-family: ${(props) => props.theme.font.secondary};
  ${(props) => props.theme.font_size.small};
  color: ${(props) => props.theme.color.black.regular};

  a {
    text-decoration: none;
    color: inherit;
  }
`;

const Art = styled.figure`
  width: 200px;
  margin: 0;
  height: 100;

  > div {
    margin-left: auto;
    width: 120%;

    @media (max-width: ${(props) => props.theme.screen.md}) {
      margin-top: 1rem;
      width: 100%;
    }
  }
`;

const ImprintLink = styled(Link)`
  text-decoration: none;
  color: black;
  align-self: center;
`;

const StyledContainer = styled(Container)`
  padding: 1rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: stech;

  @media (max-width: ${(props) => props.theme.screen.sm}) {
    flex-direction: column;
    text-align: center;
    align-items: center;
  }
`;

export default Footer;
