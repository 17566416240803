import React from "react";
import styled from "styled-components";
import { getCookieConsentValue, Cookies } from "react-cookie-consent";
import { Section, Container } from "@components/global";

const MapContainer = () => {
  return (
    <iframe
      width="100%"
      height="100%"
      style={{ border: 0 }}
      loading="lazy"
      src={`https://www.google.com/maps/embed/v1/place?key=AIzaSyAfndhTB27jnO7oKfqAhb3gXfTe69ad2Y0&q=Praxis für Physiotherapie und Osteopathie Maike Lübeck-Grieshaber`}
    ></iframe>
  );
};

const Contact = () => (
  <Section id="kontakt" accent>
    <StyledContainer>
      <div>
        <h2>Hier finden Sie uns</h2>
        <ContentGrid>
          <div>
            <DarkParagraph>
              Um einen Termin zu vereinbaren, rufen Sie uns gerne an.
            </DarkParagraph>
            <ContactContainer>
              <DarkParagraph>
                Praxis für Physiotherapie und Osteopathie <br /> Maike
                Lübeck-Grieshaber
              </DarkParagraph>
              <DarkParagraph>
                Friedrichstraße 16 <br /> 78112 St. Georgen
              </DarkParagraph>
            </ContactContainer>
            <ContactContainer>
              <DarkParagraph>
                Praxis:{" "}
                <a style={{ color: "black" }} href="tel:+4977249160550">
                  {" "}
                  07724 9160550{" "}
                </a>{" "}
              </DarkParagraph>
              <DarkParagraph>
                Handy:{" "}
                <a style={{ color: "black" }} href="tel:+4915221584277">
                  {" "}
                  0152 21584277
                </a>{" "}
              </DarkParagraph>
              <DarkParagraph>
                <a
                  style={{ color: "black" }}
                  href="mailto:info@physio-luebeck-grieshaber.de"
                >
                  info@physio-luebeck-grieshaber.de{" "}
                </a>{" "}
              </DarkParagraph>
            </ContactContainer>
            <DarkParagraph>
              Ein behindertengerechter Zugang ist vorhanden. Parkmöglichkeit
              direkt vor der Praxis.
            </DarkParagraph>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "stretch",
              width: "100%",
              height: "20rem",
            }}
          >
            {getCookieConsentValue() === "true" ? (
              <MapContainer />
            ) : (
              <div style={{ display: "flex", flexDirection: "column" }}>
                <p>
                  Stimmen sie bitte der Benutzung von Cookies zu, um uns auf der
                  Karte zu sehen
                </p>
                <a
                  style={{ textDecoration: "underline" }}
                  onClick={() => {
                    Cookies.set("CookieConsent", "true");
                    window.location.reload();
                  }}
                >
                  Zustimmen
                </a>
              </div>
            )}
          </div>
        </ContentGrid>
      </div>
    </StyledContainer>
  </Section>
);

const ContentGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 3rem;
  justify-items: start;
  margin-top: 50px;
  grid-auto-flow: column;

  a {
    svg {
      width: 100%;
    }
  }

  @media (max-width: ${(props) => props.theme.screen.md}) {
    grid-template-columns: 1fr;
    grid-auto-flow: row;
  }
`;

const StyledContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  @media (max-width: ${(props) => props.theme.screen.md}) {
    justify-content: center;
  }
`;

const ContactContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
  margin-bottom: 2rem;
`;

const DarkParagraph = styled.p`
  color: ${(props) => props.theme.color.black.regular};
`;

export default Contact;
