import React from "react";
import styled from "styled-components";

import { Section, Container } from "@components/global";

import FaqItem from "@common/FaqItem";
import ExternalLink from "@common/ExternalLink";

const AdditionalServicesItems = [
  {
    title: "Klassische Massage",
    content: () => <>Beschreibung</>,
  },
];

const AdditionalServices = () => (
  <Section id="faq">
    <Container>
      <h2 style={{ marginBottom: 40 }}>
        Insgesamt unterstützen wir sie gerne mit folgenden Therapien:
      </h2>
      <ul>
        <li>
          <ListP>Manuelle Therapie</ListP>
        </li>
        <li>
          <ListP>Osteopathie</ListP>
          <ul>
        <li>
          <ListP>Wir sind spezialisiert für die Osteopathische Behandlung von Erwachsenen, Kindern und Säuglingen.</ListP>
        </li>
        </ul>
        <li>
          <ListP>Krankengymnastik</ListP>
        </li>
        </li>
        <li>
          <ListP>KG-ZNS nach Bobath (Neurologische Therapie)</ListP>
        </li>
        <li>
          <ListP>Behandlung von Kiefergelenksstörungen (CMD)</ListP>
        </li>
        <li>
          <ListP>Lymphdrainage</ListP>
        </li>
        <li>
          <ListP>Massage</ListP>
        </li>
        <li>
          <ListP>Wärmetherapie, Kältetherapie</ListP>
        </li>
        <li>
          <ListP>Hausbesuche</ListP>
        </li>
      </ul>
    </Container>
  </Section>
);

const ListP = styled.p`
  margin: 0.5rem;
`;

export default AdditionalServices;
