import React from "react";

import CookieConsent from "react-cookie-consent";
import Layout from "@common/Layout";
import Navbar from "@common/Navbar";

import Header from "@sections/Header";
import About from "@sections/About";
import Services from "@sections/Services";
import Team from "@sections/Team";
import AdditionalServices from "@sections/AdditionalServices";
import Contact from "@sections/Contact";
import Footer from "@sections/Footer";
import Practice from "@sections/Practice";
import News from "@sections/News";

const IndexPage = () => (
  <Layout>
    <Navbar />
    <Header />
    <About />
    <Services />
    <AdditionalServices />
    <Team />
    <Contact />
    <Practice />
    <News />
    <Footer />
    {/* <CookieConsent
      buttonText="Akzeptieren"
      cookieName="PhysiotherapieMaikeLübeckGrießhaber"
      cookieSecurity={true}
      enableDeclineButton
      >
    </CookieConsent> */}
    <CookieConsent
      declineButtonText="Nur notwendige Cookies"
      enableDeclineButton
      buttonText="Akzeptieren"
      onAccept={() => window.location.reload()}
      declineButtonStyle={{ background: "#7e7e7e" }}
    >
      Diese Seite Setzt Cookies ein. Technische und funktionale Cookies
      benötigen wir zwingend, damit bei Ihrem Besuch unserer Website alles
      gelingt. Darüber hinaus verwenden wir Google Maps. Für das beste Erlebnis
      akzeptieren sie bitte alle Cookies. In unserem Impressum können Sie ihre
      Zustimmung widerrufen.
    </CookieConsent>
  </Layout>
);

export default IndexPage;
