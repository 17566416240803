import React from "react";
import styled from "styled-components";
import { StaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";

import { Container } from "@components/global";
import ExternalLink from "@common/ExternalLink";

const Header = () => (
  <StaticQuery
    query={graphql`
      query {
        team_maike: file(
          sourceInstanceName: { eq: "team" }
          name: { eq: "maike" }
        ) {
          childImageSharp {
            fluid(maxWidth: 1400) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={(data) => (
      <HeaderWrapper>
        <Container>
          <Grid>
            <Art>
              <Img fluid={data.team_maike.childImageSharp.fluid} />
            </Art>
            <Text>
              <h3>Ihre Praxis für</h3>
              <h1>
                Physiotherapie
                <br />
                und Osteopathie
              </h1>
              {/* <br />
              <Notice href="#aktuelles">
                Wir suchen Verstärkung <br /> &#10132;
              </Notice> */}
            </Text>
          </Grid>
        </Container>
      </HeaderWrapper>
    )}
  />
);

const HeaderWrapper = styled.header`
  color: ${(props) => props.theme.color.black.regular};
  background-color: ${(props) => props.theme.color.primary};
  padding-top: 96px;

  @media (max-width: ${(props) => props.theme.screen.md}) {
    padding-top: 128px;
  }
`;

const Art = styled.figure`
  width: 60%;
  margin: 0;

  > div {
    margin-left: auto;
    width: 120%;
    margin-bottom: -30%;
    border-radius: 200px;
    border: ${(props) => `0.8rem solid ${props.theme.color.white.regular}`};

    @media (max-width: ${(props) => props.theme.screen.md}) {
      width: 100%;
    }
  }
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  grid-gap: 64px;
  justify-items: center;

  @media (max-width: ${(props) => props.theme.screen.md}) {
    grid-template-columns: 1fr;
    grid-gap: 80px;

    > ${Art} {
      order: 2;
    }
  }
`;

const Text = styled.div`
  display: flex;
  flex-direction: column;
  justify-self: center;

  @media (max-width: ${(props) => props.theme.screen.md}) {
    justify-self: start;
  }
`;

const StyledExternalLink = styled(ExternalLink)`
  color: inherit;
  text-decoration: none;

  &:hover {
    color: ${(props) => props.theme.color.black.regular};
  }
`;

const Notice = styled.a`
  align-self: flex-end;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.theme.color.white.dark};
  color: black;
  border-radius: 100px;
  width: 120px;
  height: 120px;
  margin-top: 1rem;
  margin-bottom: -100px;
  // position: absolute;
  text-decoration: none;
`;

export default Header;
