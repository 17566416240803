import React from "react";
import styled from "styled-components";

import { Section, Container } from "@components/global";

const Services = () => (
  <Section id="unsere leistungen" accent>
    <StyledContainer>
      <div>
        <h2>Leistungsspektrum</h2>
        <ServiceGrid>
          <ServiceItem>
            <ServiceHeader>Manuelle Therapie</ServiceHeader>
            <CenteredParagraph>
              Die Manuelle Therapie ist eine Untersuchungs- und
              Behandlungstechnik von Funktionionsstörungen und Bewegungseinschränkungen in den Gelenken. Mit
              gezielten Mobilisationen wird das Zusammenspiel von Muskulatur,
              Bändern und Faszien wieder hergestellt.
            </CenteredParagraph>
          </ServiceItem>
          <ServiceItem>
            <ServiceHeader>Osteopathie</ServiceHeader>
            <CenteredParagraph>
              Die Osteopathie ist eine ganzheitliche Behandlungsform, die sich
              mit dem Zusammenspiel des Bewegungsapparates, der Organe, des
              Nervensystems und des Gewebes beschäftigt. Ist der Bewegungsfluss gestört, gerät der Körper aus dem Gleichgewicht. Nach einer ausführlichen Anamnese, wird den Ursachen
              von Beschwerden auf den Grund gegangen. Ziel ist es, die Selbstheilungskräfte des Körpers zu aktivieren.
            </CenteredParagraph>
          </ServiceItem>
          <ServiceItem>
            <ServiceHeader>Krankengymnastik</ServiceHeader>
            <CenteredParagraph>
              Das Ziel der Krankengymnastik ist es, Funktionsstörungen und damit
              einhergende Schmerzen zu beseitigen. Hierzu werden Übungen zur
              Muskelkräftigung, Muskeldehnung und Mobilisation durchgeführt. Darüber hinaus
              können Fehlhaltungen durch Haltungs- und Gangtechniken präventiv
              vermieden werden.
            </CenteredParagraph>
          </ServiceItem>
        </ServiceGrid>
      </div>
    </StyledContainer>
  </Section>
);

const ServiceGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-auto-flow: column;
  grid-gap: 3rem;
  justify-items: start;
  margin-top: 50px;

  a {
    svg {
      width: 100%;
    }
  }

  @media (max-width: ${(props) => props.theme.screen.md}) {
    grid-template-columns: 1fr;
    grid-auto-flow: row;
  }
`;

const StyledContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  @media (max-width: ${(props) => props.theme.screen.md}) {
    justify-content: center;
  }
`;

const ServiceItem = styled(Container)`
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 1rem;
  background: ${(props) => props.theme.color.white.dark};
  padding: 1rem;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;

  :hover {
    box-shadow: rgba(0, 0, 0, 0.3) 0px 3px 8px;
  }
`;

const ServiceHeader = styled.h3`
  margin-bottom: 2rem;
`;

const CenteredParagraph = styled.p`
  text-align: center;
`;

export default Services;
