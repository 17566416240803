import React from "react";
import styled from "styled-components";
import { StaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";

import { Section, Container } from "@components/global";

const TEAM = [
  {
    name: "Schild",
    image: "schild_weit.jpg",
    role: "",
  },
  {
    name: "Team",
    image: "team.jpg",
    role: "",
  },
  {
    name: "Zimmer",
    image: "raum_weit.jpg",
    role: "",
  },
];

const Practice = () => (
  <StaticQuery
    query={graphql`
      query {
        allFile(filter: { sourceInstanceName: { eq: "team" } }) {
          edges {
            node {
              relativePath
              childImageSharp {
                fluid(maxWidth: 650, maxHeight: 500) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    `}
    render={(data) => (
      <Section id="team" accent="secondary">
        <Container style={{ position: "relative" }}>
          <h2>Wir freuen uns auf Ihren Besuch in unserer Praxis.</h2>
          <TeamGrid>
            {TEAM.map(({ name, image, role }) => {
              const img = data.allFile.edges.find(({ node }) => {
                return node.relativePath === image;
              }).node;

              return (
                <div key={name}>
                  <Img fluid={img.childImageSharp.fluid} alt={name} />
                </div>
              );
            })}
          </TeamGrid>
        </Container>
      </Section>
    )}
  />
);

const TeamGrid = styled.div`
  display: grid;
  grid-gap: 1em;
  // grid-template-columns: repeat(auto-fill, 375px);
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: min-content;
  justify-content: center;
  margin-top: 50px;

  @media (max-width: ${(props) => props.theme.screen.lg}) {
    justify-content: start;
  }

  @media (max-width: ${(props) => props.theme.screen.md}) {
    width: 100%;
  }

  @media (max-width: ${(props) => props.theme.screen.xs}) {
    grid-gap: 24px;
    grid-template-columns: 1fr;
  }
`;

export default Practice;
